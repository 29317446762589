<template>


    <NuxtLinkLocale ref="link" v-slot="{ href, isActive, isExactActive, navigate, route, prefetch }" :to="props.to"
        tabindex="-1" :class="props.class" :locale="props.locale" :prefetch="props.prefetch" :prefetchOn="props.prefetchOn"
        :noPrefetch="props.noPrefetch || undefined" :prefetchedClass="props.prefetchedClass">

        <UButton
        :class="[
            'cursor-pointer',
            props.baseClass,
            isActive
                ? props.activeClass
                : isExactActive
                    ? props.exactActiveClass
                    : props.inactiveClass
        ]" :color="isActive ? props.activeColor : props.color" v-bind="buttonProps">
            <template #default>
                <slot name="default" v-bind="{ href, isActive, isExactActive, navigate, route, prefetch }" />
            </template>
            <template #leading>
                <slot name="leading" v-bind="{ href, isActive, isExactActive, navigate, route, prefetch }" />
            </template>
            <template #trailing>
                <slot name="trailing" v-bind="{ href, isActive, isExactActive, navigate, route, prefetch }" />
            </template>
        </UButton>


    </NuxtLinkLocale>




</template>

<script lang="ts" setup>
    import type { NavigationFailure, RouteLocationResolvedGeneric, UseLinkReturn } from "#vue-router"
    import type { ButtonProps } from "#ui/components/Button.vue";
    import type { Locale } from '#i18n'


    interface mySlots {
        href: string,
        isActive: boolean,
        isExactActive: boolean,
        navigate: (e?: MouseEvent) => Promise<void | NavigationFailure>,
        route: RouteLocationResolvedGeneric,
        prefetch: boolean
    }

    const slots = defineSlots<{
        default(props: mySlots): any,
        leading(props: mySlots): any,
        trailing(props: mySlots): any,
    }>()

    defineOptions({
        inheritAttrs: false
    })

    const attrs = useAttrs()
    type Color = Pick<ButtonProps, "color">;


    const props = defineProps<ButtonProps & { activeColor?: Color['color'], locale?: Locale, baseClass?: string }>()


    function splitReactiveProps<T extends Record<string, any>, K extends keyof T>(
        props: T,
        keys: K[]
    ): [Record<K, T[K]>, Omit<T, K>] {
        const included = computed(() => {
            const result: Partial<Record<K, T[K]>> = {};
            keys.forEach((key) => {
                result[key] = props[key];
            });
            return result as Record<K, T[K]>;
        });

        const excluded = computed(() => {
            const result: Partial<Omit<T, K>> = {};
            Object.keys(props).forEach((key) => {
                if (!keys.includes(key as K)) {
                    result[key as keyof Omit<T, K>] = props[key as keyof T];
                }
            });
            return result as Omit<T, K>;
        });

        return [included.value, excluded.value];
    }




    // Exclure `to` de props
    // Extraire les propriétés tout en maintenant la réactivité
    function omit<T, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> {
        const clone = { ...obj, ...attrs };
        keys.forEach(key => delete clone[key]);
        return clone;
    }


    // Exclure `to` et `locale`
    const buttonProps = computed(() => omit(props, ['to', 'locale', 'color', 'noPrefetch', 'prefetch', 'prefetchOn', 'prefetchedClass', 'class', 'activeClass', 'inactiveClass', 'exactActiveClass', 'prefetchedClass']));


</script>